  <template>
  <main>
    <!-- HEADER =============================================================== -->
    <header class="mt-1 mb-2" v-if="orgServicesInfo">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="org-avatar mr-2">
            <b-avatar
              variant="purple"
              class="avatar m-1"
              :text="orgServicesInfo.name[0]+orgServicesInfo.name[1]"
              :size="100"
            />
          </div>
          <h1>{{orgServicesInfo.name}}</h1> 
        </div>

       
          <div class="align-self-center d-flex align-items-center text-right mr-3"> <!-- icons right -->
            <div class="mt-1 mx-1">
              <p class="p-0 m-0 text-center">
                <b>{{orgServicesInfo.services}}</b>
              <b-icon icon="display" shift-v="2" class="mx-50"/></p>
              <p class="text-secondary"><b>{{$t('integrator.pages.organization_view.total_services')}}</b></p>
            </div>
            <div class="divider"/>
            <div class="mt-1 mx-1">
              <p class="p-0 m-0 text-center"><b>{{orgServicesInfo.rating}}</b> <b-icon icon="star" shift-v="2" class="mx-50"/></p>
              <p class="text-secondary"><b>{{$t('integrator.pages.organization_view.average_rating')}}</b></p>
            </div>
            <div class="divider"/>
            <div class="mt-1 mx-1">
              <p class="p-0 m-0 text-center"><b>{{getDownloadCount}}</b> <b-icon icon="download" shift-v="2" class="mx-50"/></p>
              <p class="text-secondary"><b>{{$t('integrator.pages.organization_view.installations')}}</b></p>
            </div>
          </div>
      </div>    
      
      <div class="my-3 d-flex">
        <b-input-group class="w-50">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          
          <b-form-input placeholder="Search services..." v-model="searchTerm" size="md">

          </b-form-input>
        </b-input-group>
        <span class="mt-50 mx-1 text-secondary"><small></small></span>
      </div>

    </header>
    
    <div v-else> <!-- header skeleton -->
      <div class="d-flex align-content-center justify-content-start">
        <b-skeleton width="130px" height="120px" animation="fade"/>
        <b-skeleton width="200px" height="20px" class="ml-2 mt-4" animation="fade"/>
      </div>

      <b-skeleton width="50%" height="35px" class="my-3" animation="fade"/>
    </div>
    <!-- END OF HEADER ======================================================== -->

    <b-row class="mt-3" v-if="filteredServices">
      <b-col v-for="(card, index) in filteredServices" :key="card.identifier" md="12" lg="6" xl="4" class="px-2">
        <small-service-card 
          v-model="filteredServices[index]"
          redirectsTo="view"
        />
      </b-col>
    </b-row>
    
   <b-row class="mt-3" v-else>
      <b-col v-for="i in 10" :key="'card-skeleton-'+i" sm="6" md="4" lg="3" class="px-2">
        <small-skeleton/>
      </b-col>
    </b-row>

  </main>
</template>

<script>
import moment from "moment";

import {
  BContainer,
  BCol,
  BRow,
  BCard,
  BCardHeader,
  BCardBody,
  BAvatar,
  BIcon,
  BTab,
  BTabs,
  BButton,
  BCardText,
  BCardGroup,
  BFormRating,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BSkeleton,
  BSpinner,
} from "bootstrap-vue";
import DefaultChanger from '@/layouts/components/DefaultChanger.vue';
import Avatar from '@/views/pages/integrator/components/General/Avatar.vue';
import ServiceBadges from '@/views/pages/integrator/components/General/Badges.vue'
import { mapGetters } from 'vuex'
import ReadmeEdit from "@/views/pages/integrator/components/EditService/ReadmeEdit.vue";
import { makeToast } from '@/layouts/components/Popups';
import SmallServiceCard from '@/views/pages/integrator/components/ServiceCard/Small.vue';
import SmallSkeleton from '@/views/pages/integrator/components/ServiceCard/Small_Skeleton.vue';

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BIcon,
    BTab,
    BTabs,
    BButton,
    DefaultChanger,
    Avatar,
    ServiceBadges,
    BCardText,
    ReadmeEdit,
    BCardGroup,
    BFormRating,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    SmallServiceCard,
    BSkeleton,
    BSpinner,
    SmallSkeleton
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters('serviceModule', ['getIntegratorServiceByID', 'getServicesByOrg', 'getOrgServicesInfo']),
    service() {
        return this.getIntegratorServiceByID(this.organizationID, this.serviceID)
      },
    organizationID(){
      return localStorage.getItem('selectedOrganization');
    },
    shownOrgID(){
      return this.$route.params.orgID;
    },
    services(){
      return this.getServicesByOrg(this.organizationID)
    },
    orgServicesInfo(){
      return this.getOrgServicesInfo(this.organizationID)
    },
    getDownloadCount(){
      let r = this.orgServicesInfo.downloads
      if (r>1000){
        r = Math.floor(r / 1000) + 'K'
      }
      return r
    },
    filteredServices(){
      let search = this.searchTerm

      if (!this.services) return null;

      return this.services.filter((el) => {
        return (
          el.identifier.toLowerCase().indexOf(search.toLowerCase()) > -1 
          || 
          el.name.toLowerCase().indexOf(search.toLowerCase()) > -1
          ||
          el.integrator_category.label.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
      })
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init() {
        this.getServicesByOrganization()
      },
      getServicesByOrganization() {
        this.$store.dispatch('serviceModule/fetchIntegratorServicesByOrganization', {organizationID: this.shownOrgID, currentOrgID: this.organizationID}).then(() => {
        }).catch((error) => {
          console.error(error)
          this.$router.push('/error-404');
        })
      },
    }
};
</script>



<style lang="scss" scoped>

.org-avatar{
  background-color: #1d2439;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.org-avatar .avatar{
  background-color: #272c4f !important;
}

.divider{
  width: 1px;
  background-color: #82868b;
  height: 30px;
  margin: 0 15px;
}

</style>  